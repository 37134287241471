<template>
    <div class="avater">
        <van-image
  round
  :width="width"
  :height="height"
  :src="avaterImg"
/>
    </div>
</template>

<script>
export default {
    name: 'avater',
    props:{
     avaterImg:{
        type:String,
        // default:require('@/assets/images/userLogo.jpg'),
        default:'',
     },
     width:{
        type:String,
        default:'40px'
     },
     height:{
        type:String,
        default:'40px'
     }
    },
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>