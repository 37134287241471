<template>
    <div class="empty-content">
        <van-empty :description="description" />
    </div>
</template>

<script>
export default {
    name: 'EmptyContent',
    props:{
         description:{
            type:String,
            default:'暂无内容'
         }
    },
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>