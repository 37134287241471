<template>
    <div class="header-tab-box">
            <van-nav-bar :title="title" left-arrow @click-left="$router.back()" />
        </div>
</template>

<script>
export default {
    name: 'NavTab',
    props:{
        title:{
           type:String,
           default:'',
        }
    },
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
&>.header-tab-box{
    /deep/ .van-nav-bar__title{
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 2px;
    }
    }
</style>