<template>
    <div class="follow-box">
        <NavTab :title="title"></NavTab>
        <FollowOrFans  ref="FollowOrFans" :list="followList" :showRightContent="false" @getFollowList="getFollowList"
        @getUserId="getUserId"
         @click.native="toHomePage"></FollowOrFans> 
    </div>
</template>

<script>
import NavTab from '@/components/Mobile/NavTab'
import FollowOrFans from '@/components/Mobile/FollowOrFans'
import { mapState } from 'vuex'
export default {
    name: 'Follow',
    components:{
        NavTab,
        FollowOrFans,
    },
    data() {
        return {
            followList:[],
            //用户id
            user_id:0,
            // 用户昵称
            nickname:''
        };
    },
    computed: {
        ...mapState(['userInfo']),
        title()
        {
            if(this.$route.query.user_id && this.userInfo.id == this.$route.query.user_id)
            {
                return '我关注的人';
            }
            else{
                return `${this.nickname}关注的人`; 
            }
        }
    },
    activated() {
        if(!this.$route.query.user_id)
        {
            return;
        }
        this.getFollowList();
        this.getUserInfoById();
        // console.log(123,Number(this.$route.query.user_id) == this.userInfo.id);
        // console.log(111, Number(this.$route.query.user_id) == this.userInfo.id || this.userInfo.id ? '我关注的人':'ta关注的人')
    },

    methods: {
          // 获取关注列表
      async  getFollowList()
        {
            try {
                 let user_id = +this.$store.state.userInfo.id;
            let {data:res} = await this.api
        .postFormAPISM(
          {
            user_id: Number(this.$route.query.user_id) || user_id,
            follow_type:1
          },
          "/user/getuserlist"
        )
        this.followList = res.data.users;
        }  catch (error) {
                console.log(error);
            }
        },
        //获取用户id
        getUserId(user_id)
        {
           this.user_id = user_id;
        },
        //跳转到主页
        toHomePage()
        {
           this.$router.push({path:'/homepage',query:{user_id:this.user_id}});

        },
        //通过id查询用户信息
       async getUserInfoById()
        {
            console.log('user_id',this.user_id);
            let {data:res} = await this.api
        .postFormAPISM(
          {
            user_id: Number(this.$route.query.user_id)
          },
          "/user/getuserinfobyid"
        );
        this.nickname=res.data.user.nickname;
        }
    },
};
</script>

<style lang="less" scoped>
.follow-box{
    height: 100vh;
    background: #f8f8f8;
}
</style>