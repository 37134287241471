<template>
    <div class="fans-follow-box">
        <div class="fans-list" v-if="list.length !== 0">
            <div class="fans-item" v-for="item in list" :key="item.user.id" @click="getUserId(item.user.id)">
                <div class="left-content">
                    <avater width="40px" height="40px" :avaterImg="item.user.avatar"></avater>
                    <div class="user-info">
                        <div class="user-name">{{ item.user.nickname }}</div>
                        <div class="fans-count">粉丝{{ item.fans_count }}人,关注{{ item.follow_count }}人</div>
                    </div>
                </div>
                <van-icon name="arrow" v-if="showRightContent"  />
                <van-button v-show="item.user.id !==userInfo.id"  v-else :class="item.user_follow_ship.is_follow ? 'active' : ''" round block color="#f5f5f5"
                    @click.stop="handleFollow(item)">{{ item.user_follow_ship.is_follow ? '已关注' : '关注' }}</van-button>
            </div>
        </div>
        <div class="no-fans" v-if="list.length === 0">
            <EmptyContent description="暂无内容" />
        </div>
        <MessagePoint mesageTxt="关注成功" :show="showFollow"></MessagePoint>
        <MessagePoint mesageTxt="取消关注" :show="cancelFollow"></MessagePoint>
    </div>
</template>

<script>
import avater from '@/components/Mobile/avater'
import MessagePoint from '@/components/Mobile/MessagePoint'
// 空状态
import EmptyContent from "@/components/Mobile/EmptyContent"
import { mapState } from 'vuex';
export default {
    name: 'FollowOrFans',
    components: {
        avater,
        MessagePoint,
        EmptyContent
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        showRightContent: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            showFollow: false,
            cancelFollow: false
        };
    },

    mounted() {

    },
   computed:{
    ...mapState(['userInfo']),
   },
    methods: {
        // 关注
        async handleFollow(item) {
            try {
                //  想要关注的用户id
                const target_user_id = item.user.id;
                let is_follow = item.user_follow_ship.is_follow;
                // 发起关注请求
                let res = await this.api.
                    postFormAPISM(
                        {
                            target_user_id,
                            is_follow: !is_follow,
                            remark_name: ""
                        },
                        "/user/follow"
                    );
                item.user_follow_ship.is_follow = !item.user_follow_ship.is_follow;
                if (item.user_follow_ship.is_follow) {
                    this.showFollow = true;
                    setTimeout(() => {
                        this.showFollow = false;
                    }, 500);
                }
                else {
                    this.cancelFollow = true;
                    setTimeout(() => {
                        this.cancelFollow = false;
                    }, 500);
                }
            }

            catch (error) {
                console.log(error);
            }
            //   重新刷新关注列表
            this.$emit('getFollowList');
        },
      // 获取到每个用户的id
      getUserId(id)
      {
         //  传回父组件
         this.$emit('getUserId',id);
         // 去主页
         this.$router.push({path:'/homepage',query:{user_id:id}});
      }
    }
};
</script>

<style lang="less" scoped>
.fans-follow-box {
    &>.fans-list {

        &>.fans-item {
            position: relative;
            background: #fff;
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &>.left-content {
                display: flex;
                align-items: center;

                &>.user-info {
                    padding-left: 15px;
                    display: flex;
                    flex-direction: column;

                    &>.user-name {
                        width: 200px;
                        font-size: 14px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    &>.fans-count {
                        font-size: 12px;
                        margin-top: 5px;
                        color: #989898;
                    }
                }
            }

            &::before {
                position: absolute;
                bottom: 0;
                content: '';
                width: calc(100% - 30px);
                height: 1px;
                background: #f8f8f8;

            }

            & .van-button {
                height: 27px;
                width: 80px;

                &.active {
                    & .van-button__text {
                        color: #c2c2c2;
                    }

                }

                & .van-button__text {
                    color: #1989fa;
                }
            }
        }
    }

    &>.no-fans {
        margin-top: 30px;
    }
}
</style>