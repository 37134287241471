<template>
    <div class="message">
        <van-notify v-model="show" type="success" :class="mesageTxt.length >4 ? 'active':''">
        <van-icon name="bell" style="margin-right: 4px;" />
        <span>{{ mesageTxt }}</span>
      </van-notify>
    </div>
</template>

<script>
export default {
    name: 'MessagePoint',
    props:{
        mesageTxt:{
            type:String,
            default:'关注成功'
        },
        show:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
        };
    },
    watch:{
       
    },
    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
 .message
 {
    &>.van-popup {
        position: fixed;
        width: 84px;
        padding: 4px 16px;
        border-radius: 50px;
        font-size: 12px;
        margin: 0 calc(50% - 50px);
        top: 20px;
        background-color: rgb(67, 122, 239);
        &.active{
           width: 104px;
        }
        &>i {
          display: none;
        }
      }
 }
</style>